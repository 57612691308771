import { Fragment } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Quote } from "react-bootstrap-icons";
import "./About.css";
import { Reveal } from "../../animate/Reveal";

function About() {
  return (
    <Fragment>
      <div className="aboutHeading">
        <Reveal>
          <h1>
            We are on a mission to revolutionize user messaging experience all
            around the world.
          </h1>
        </Reveal>
        <Reveal>
          <p>
            Our platform is designed to advance social messaging interaction
            giving users a great experience regardless of location worldwide.
          </p>
        </Reveal>
      </div>
      <div className="aboutDescription">
        <h2>Who We Are</h2>
        <p>
          SoloChat Messenger is a revolutionary social messaging app that
          enhances users' messaging experience by utilizing advanced
          technologies such as Augmented Reality, Machine Learning, and Sticker
          Animations. Our aim is to make getting acquainted more enjoyable and
          effortless while connecting you with your neighbors, colleagues, and
          family. With SoloChat Messenger, you can send texts, photos, videos,
          and even audio messages and make video and audio calls with anyone
          around the world. Our innovative messaging feature allows you to share
          moments with people who matter and engage in facetime video sessions
          with colleagues and groups in your circle inside a certified room
          session. With our advanced search system, you can easily find the
          closest people around you and start chatting on SoloChat Messenger. At
          SoloChat Messenger, we believe that communication is the core of our
          services. We understand how sharing activities and experiences through
          video communications, VoIP, instant messaging is revolutionizing the
          world, and we strive to be at the forefront of this revolution.
        </p>
        <p>
          Our initial idea was to create a personal chat meaning just you and
          the person you're chatting with. However, we have expanded our
          services to cater to the needs of our users better.
        </p>
      </div>

      <Container>
        <Row className="aboutStatements">
          <Col className="aboutVision" md="12" lg="5">
            <Reveal>
              <h3>Our Vision</h3>
              <p>
                To promote friendship and creativity through social interaction,
                no matter where they are in the world.
              </p>
            </Reveal>
          </Col>
          <Col className="aboutCompany" md="12" lg="5">
            <Reveal>
              <h3>Our Company</h3>
              <p>
                SoloChat is a dynamic startup based in Nigeria,
				specializing in software development and communication services.
				Our flagship product, SoloChat Messenger, is a social networking
				and communication app that leverages cutting-edge technology to
				enhance users' messaging experience. Our headquarter is located 
				in Lagos, Nigeria, and we currently have a startup size of 5–20 employees.
				We take pride in being a Nigerian company and are committed to 
				contributing to the growth of the technology industry in Nigeria.
				We believe that with our innovative	products and services, 
				we can revolutionize the way people communicate and connect with each other.
              </p>
            </Reveal>
          </Col>
        </Row>
        <Row className="aboutTeam">
          <h2>Meet the Team</h2>
          <Col className="aboutFounderImg">
            <img
              src="solochatFounder.jpeg"
              alt="Favour_Gabriel_Aighangbe_solochatFounder"
            />
          </Col>
          <Col className="aboutFounder">
            <Quote color="brown" size={50} />
            <Reveal>
              <h4>
                We believe that with our innovative products and services, we
                can revolutionize the way people communicate and connect with
                each other.
              </h4>
            </Reveal>
            <Reveal>
              <span>Favour Gabriel Aighangbe - Founder and CEO</span>
            </Reveal>
          </Col>
        </Row>
        <Row>
          <Col className="teamCard">
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src="solochatCTR.jpeg" />
              <Card.Body>
                <Card.Title>Uche Livingstone</Card.Title>
                <Card.Text>CTO and HR</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="teamCard">
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src="soloChatEngineer.jpeg" />
              <Card.Body>
                <Card.Title>Francis Inegbenose</Card.Title>
                <Card.Text>Frontend Engineer</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="teamCard">
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src="soloChatJakpa(1).jpg" />
              <Card.Body>
                <Card.Title>Jakpa Edison</Card.Title>
                <Card.Text>Product Manager</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col className="teamCard">
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src="solochatsophia.jpg" />
              <Card.Body>
                <Card.Title>Sophia Kalagbor</Card.Title>
                <Card.Text>Engineer</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col className="teamCard">
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src="solochataustine.jpg" />
              <Card.Body>
                <Card.Title>Austin Ibekwe</Card.Title>
                <Card.Text>Marketing</Card.Text>
              </Card.Body>
            </Card>
          </Col> */}
        </Row>
      </Container>
    </Fragment>
  );
}

export default About;
